import { render, staticRenderFns } from "./Job.vue?vue&type=template&id=c8f22936&scoped=true"
import script from "./Job.vue?vue&type=script&lang=js"
export * from "./Job.vue?vue&type=script&lang=js"
import style0 from "./Job.vue?vue&type=style&index=0&id=c8f22936&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8f22936",
  null
  
)

export default component.exports