<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
          />
        </v-col>
        <v-col md="12">
          <h3 class="filtertitle">Filter {{ headercontent.title }}</h3>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="jobPosition"
                  outlined
                  label="Job Position"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>

              <v-col cols="3">
                <v-select
                  :items="subscriptionTypes"
                  v-model="subscription"
                  label="Subscription Type"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="lookingfors"
                  v-model="lookingfor"
                  label="Job Level"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="jobstatus"
                  v-model="status"
                  item-text="text"
                  item-valye="value"
                  label="Job Status"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  :items="allCategories"
                  v-model="category"
                  item-text="text"
                  item-value="value"
                  label="Job Category"
                  outlined
                ></v-select>
              </v-col>

              <v-col cols="12" class="submitbtn">
                <v-btn class="submitbutton btn-dark" @click="validate">
                  Filter {{ headercontent.title }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
        <v-col md="12">
          <v-simple-table class="table">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">S/N</th>
                  <th class="text-left">Company Name</th>
                  <th class="text-left">Job Title</th>
                  <th class="text-left">Job Category</th>
                  <th class="text-left">Subscription Type</th>
                  <th class="text-left">Job Level</th>
                  <th class="text-left">Job Start Date</th>
                  <th class="text-left">Job End Date</th>
                  <th class="text-left">Job Views</th>
                  <th class="text-left">Job Applied</th>
                  <th class="text-left">Job Applied via CV</th>
                  <th class="text-left">Job Status</th>
                  <th class="text-right" colspan="3">Action</th>
                </tr>
              </thead>
              <tbody v-if="!loading">
                <tr v-for="(item, index) in allJob.results.list" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.jobInformation.companyName }}</td>
                  <td>{{ item.jobInformation.jobPosition }}</td>
                  <td>{{ item.jobInformation.category }}</td>
                  <td>{{ item.jobInformation.subscriptionType }}</td>
                  <td>{{ item.jobInformation.jobLevel }}</td>
                  <td>{{ item.jobInformation.jobStartDate | formatDate }}</td>
                  <td>{{ item.jobInformation.jobEndDate | formatDate }}</td>
                  <td>{{ item.view }}</td>
                  <td>
                    {{ item.appliedUser.length }}
                    <v-icon
                      small
                      v-if="item.appliedUser.length > 0"
                      @click="toggleResume(item.appliedUser, item._id)"
                    >
                      mdi-eye
                    </v-icon>
                  </td>
                  <td>
                    {{ item.appliedCV.length }}
                    <v-icon
                      v-if="item.appliedCV.length > 0"
                      small
                      @click="toggleCv(item.appliedCV, item._id)"
                    >
                      mdi-eye
                    </v-icon>
                  </td>

                  <td>
                    <span
                      class="deactive"
                      v-if="!item.status"
                      @click="jobStatus(item)"
                      >Deactive</span
                    >
                    <span
                      v-if="item.status"
                      class="active"
                      @click="jobStatus(item)"
                      >Active</span
                    >
                  </td>

                  <td class="text-right">
                    <router-link
                      :to="
                        '/dashboard/job/add/' +
                        item.jobInformation.userHandle +
                        '/' +
                        item.jobInformation.slug
                      "
                      ><v-icon class="icons-action">
                        mdi-pencil
                      </v-icon></router-link
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div
            v-if="
              allJob.results && allJob.results.pagination.total <= 0 && !loading
            "
            class="nodatabox"
          >
            No Data Found
          </div>
          <div v-if="loading">
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
          </div>
          <ul class="pagination-button" v-if="allJob.results != undefined">
            <li>
              Page :
              <span v-if="allJob.results.pagination.next">{{
                (allJob.results.pagination.next - 1) * this.limit
              }}</span
              ><span v-if="!allJob.results.pagination.next">{{
                allJob.results.pagination.total
              }}</span>
              -
              {{ allJob.results.pagination.total }}
            </li>
            <li>
              <div>
                <v-btn
                  v-if="allJob.results.pagination.previous"
                  @click="prevPage(allJob.results.pagination.previous)"
                  class="btn-dark"
                  >Prev</v-btn
                >

                <v-btn
                  v-if="allJob.results.pagination.next"
                  @click="nextPage(allJob.results.pagination.next)"
                  class="btn-dark"
                  >Next</v-btn
                >
              </div>
            </li>
          </ul>
        </v-col>
      </v-row>
      <div v-if="cvStatus" class="popUp">
        <div class="innerPop">
          <header>
            <h1>CV</h1>
            <v-icon @click="cvStatus = false"> mdi-close </v-icon>
          </header>

          <ul>
            <li v-for="(data, index) in cvs" :key="index">
              <div>
                {{ data.name }}
                <v-btn
                  class="btn-dark"
                  small
                  @click="() => getSelecteCV(data.name)"
                  >View
                </v-btn>
                <v-icon v-if="data.wishlist" color="red"> mdi-heart </v-icon>
                <v-icon v-if="!data.wishlist"> mdi-heart-outline </v-icon>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div v-if="resumeStatus" class="popUp">
        <div class="innerPop">
          <header>
            <h1>Resume</h1>
            <v-icon @click="resumeStatus = false"> mdi-close </v-icon>
          </header>

          <ul>
            <li v-for="(data, index) in resumes" :key="index">
              <div>
                {{ data.name }}
                <v-btn
                  class="btn-dark"
                  small
                  @click="() => getSelecteResume(data.name)"
                  >View
                </v-btn>
                <v-icon v-if="data.wishlist" color="red"> mdi-heart </v-icon>
                <v-icon v-if="!data.wishlist"> mdi-heart-outline </v-icon>
                <ResumeView
                  :showbox="showbox && id == data.name"
                  @closepopup="closepopup"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
import ResumeView from "@/components/ResumeView";
export default {
  name: "Job",
  components: { PageHeader, ResumeView },
  data: () => ({
    headercontent: {
      title: "Job Listing",
    },
    resumeStatus: false,
    cvStatus: false,
    resumes: [],
    cvs: [],
    title: "",
    adsposition: "",
    valid: true,
    lookingfor: "",
    lookingfors: ["Top Level", "Senior Level", "Mid Level", "Entry Level"],
    subscriptionTypes: [
      "Free Job",
      "Premium Job",
      "Standard Job",
      "Featured Job",
    ],
    jobstatus: [
      { value: true, text: "Active" },
      { value: false, text: "Deactive" },
    ],
    status: true,
    skip: 1,
    limit: 30,
    jobPosition: "",
    subscription: "",
    filterjob: {},
    loading: true,
    selectedResumeData: [],
    selectedCvData: [],
    id: undefined,
    showbox: false,

    allCategories: [], // To store all unique categories
    category: "",
  }),
  methods: {
    ...mapActions([
      "getAllJob",
      "updateJobStatus",
      "getAllJobFilter",
      "selectedResume",
      "selectedCv",
      "wishlistAction",
      "getResumeView",
      "getSelectedCView",
    ]),

    extractUniqueCategories() {
      const categories = new Set();
      this.allJob.results.list.forEach((job) => {
        categories.add(job.jobInformation.category);
      });
      this.allCategories = Array.from(categories).map((cat) => ({
        text: cat,
        value: cat,
      }));
    },

    async getSelecteCV(data) {
      this.id = data;
      await this.getSelectedCView(data);
      this.resumeView = this.allJobView.results.resume_file;
      window.open("https://app.necojobs.com.np" + this.resumeView, "_blank");
    },
    async getSelecteResume(data) {
      this.id = data;
      await this.getResumeView(data);
      this.showbox = true;
    },
    async postSelectResume(data) {
      this.selectedResumeData = [...this.selectedResumeData, data];
      await this.selectedResume({
        id: this.id,
        selectedResume: this.selectedResumeData,
      });
    },
    async postSelectCV(data) {
      this.selectedCvData = [...this.selectedCvData, data];
      await this.selectedCv({
        id: this.id,
        selectedCV: this.selectedCvData,
      });
    },
    toggleResume(data, id) {
      this.id = id;
      this.resumeStatus = !this.resumeStatus;
      this.resumes = this.resumeStatus ? data : [];
    },
    toggleCv(data, id) {
      this.id = id;
      this.cvStatus = !this.cvStatus;
      this.cvs = this.cvStatus ? data : [];
    },
    async wishAction(data) {
      console.log(data);
      // await this.wishlistAction({ connectedTo: data });
    },
    validate() {
      this.filterjob = {};
      if (this.jobPosition != "") {
        this.filterjob = Object.assign(
          {
            $or: [
              {
                "jobInformation.jobPosition": {
                  $regex: this.jobPosition,
                  $options: "i",
                },
              },
              {
                "jobInformation.companyName": {
                  $regex: this.jobPosition,
                  $options: "i",
                },
              },
            ],
          },
          this.filterjob
        );
      }

      if (this.subscription != "") {
        this.filterjob = Object.assign(
          {
            subscriptionType: this.subscription,
          },
          this.filterjob
        );
      }

      if (this.lookingfor != "") {
        this.filterjob = Object.assign(
          {
            "jobInformation.jobLevel": this.lookingfor,
          },
          this.filterjob
        );
      }

      if (this.category !== "") {
        this.filterjob = Object.assign(
          {
            "jobInformation.category": this.category,
          },
          this.filterjob
        );
      }

      this.filterjob = Object.assign(
        {
          status: this.status,
        },
        this.filterjob
      );

      this.getAllJobFilter({
        filterjob: JSON.stringify(this.filterjob),
        ...{ limit: this.limit, skip: this.skip },
      });
    },

    async nextPage(data) {
      this.loading = true;
      await this.getAllJobFilter({
        filterjob: JSON.stringify(this.filterjob),
        ...{ limit: this.limit, skip: data },
      });
      this.loading = false;
    },
    async prevPage(data) {
      this.loading = true;
      await this.getAllJobFilter({
        filterjob: JSON.stringify(this.filterjob),
        ...{ limit: this.limit, skip: data },
      });
      this.loading = false;
    },
    async jobStatus(item) {
      let updateStatus = {
        id: item._id,
        status: !item.status,
        slug: item.slug,
      };
      await this.updateJobStatus(updateStatus);
    },
    closepopup(e) {
      this.showbox = e;
    },
  },
  computed: {
    ...mapGetters(["allJob", "allloadingAd", "allJobView"]),
  },
  async created() {
    await this.getAllJob({ limit: this.limit, skip: this.skip });
    this.extractUniqueCategories();
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.form-section {
  padding: 15px 0;
  .col {
    padding: 0 10px;
  }
}
.filtertitle {
  margin-bottom: 10px;
}
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  margin-top: -10px;
}

.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
}
.deactive,
.active {
  display: block;
  padding: 2px;
  width: 100px;
  text-align: center;
  color: $white;
  border-radius: 20px;
}
.deactive {
  background: red;
  cursor: pointer;
}
.active {
  background: green;
  cursor: pointer;
}
.active {
  background: green;
}
.pagination-button {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  .btn-dark {
    margin-top: 0;
    margin-left: 10px;
  }
}
.table-heading,
.pagination-button {
  border: 1px solid $menucolor;
  margin-bottom: 0;
  padding: 10px;
  align-items: center;
}
.icons-action {
  margin: 10px;
  font-size: $iconfont;
}
.submitbtn {
  display: flex;
  justify-content: space-between;
}

.popUp {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.innerPop {
  background: #fff;
  border-radius: 20px;
  width: 400px;
  padding: 15px;
  header {
    display: flex;
    justify-content: space-between;
    h1 {
      font-weight: 600;
    }
  }
  ul {
    list-style: none;
    padding-left: 0;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    height: 300px;
    overflow-y: scroll;
    padding-top: 10px;
    &::-webkit-scrollbar {
      display: none;
    }
    li {
      margin-left: 0;
      border: 1px solid #ebe7e7;
      padding: 10px 15px;
      margin-bottom: 5px;
      border-radius: 5px;
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}
</style>
